import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppAlertService } from 'app/shared/service/app/alert.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, firstValueFrom, map, Observable, startWith, Subject, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { EmpresaService } from '@shared/service/app/empresa.service';
import { IProveedor } from 'app/modules/administracion/proveedores/models/proveedor';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MY_DATE_FORMATS } from '@shared/constants/date-format';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { IAlertConfig } from '@shared/models/alert';
import { EnviarEmailModalService } from './enviar-email.service';
import { IImagenAdjunto } from '@shared/models/imagen-adjunto';
import { QuillEditorComponent } from 'ngx-quill';
import { ICreateEmail } from './enviar-email';

@Component({
    selector: 'app-enviar-email-component',
    templateUrl: './enviar-email.component.html',
    styleUrls: ['./enviar-email.component.scss'],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
})
export class EnviarEmailModalComponent implements OnInit {
    // Necesarios para la aplicacion
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    form: FormGroup;
    color_primario: string;
    color_secundario: string;
    default_color: string = 'bg-primary';
    showAlert = false;
    _alertConfig: IAlertConfig;

    // Particulares del componente
    proveedores: IProveedor[];
    filteredProveedores: Observable<IProveedor[]>;
    selectedProveedores: IProveedor[] = [];
    titleImagen: boolean;
    protected fileAdjuntoImagen: any;
    protected validExtension = [
        '.pdf'
    ];
    selectedFiles: File[] = [];
    imagen: string;
    adjunto: IImagenAdjunto | null = null;
    adjuntos = [];
    selectedOption: string = '';

    //  Configuracion para Quill
    editorContent = ''; // Contenido del editor
    // Configuración de la barra de herramientas
    editorModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // Estilos de texto
            [{ header: [1, 2, 3, false] }],           // Tamaños de encabezado
            [{ list: 'ordered' }, { list: 'bullet' }], // Listas
            ['link', 'image'],               // Enlaces, imágenes y videos
            ['clean']                                 // Eliminar formato
        ]
    };


    @Output() public enviadoExitosamente: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(QuillEditorComponent) quillEditor: QuillEditorComponent;

    @Input() public _endpointService: string = '';
    @Input() public title: string = 'Enviar Email';

    @Input() public permiteAdjunto: boolean = false;
    isHovering: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _enviarEmailModalService: EnviarEmailModalService,
        private _empresaService: EmpresaService,
        private alert: AppAlertService,
        public dialogRef: MatDialogRef<EnviarEmailModalComponent>,
    ) {
        // Elementos parametrizables
        this._endpointService = data._endpointService;
        this.title = data.title;
        this.permiteAdjunto = data.permiteAdjunto;

        // Subscribe to empresa data
        this._empresaService.empresa$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(empresa => {
                this.color_primario = empresa.color_primario;
                this.color_secundario = empresa.color_secundario;
            });
    }

    onHover(hovering: boolean, $event: MouseEvent) {
        $event.preventDefault();
        this.isHovering = hovering;
    
        const target = $event.target as HTMLElement;
        const span = target.querySelector('span');

        if (span) {
            span.style.color = hovering ? 'white' : '';
        }
    
        if (hovering) {
            target.style.setProperty('background-color', this.color_primario, 'important');
        } else {
            target.style.removeProperty('background-color');
            target.style.removeProperty('color');
        }
    }

    // Alert
    subscribeAlert() {
        this.alert.emitConfig.subscribe({
            next: (config: IAlertConfig) => {
                this.showAlert = true;
                this._alertConfig = {
                    ...config,
                    appearance: 'border',
                };
                let time = 2;
                timer(100, 1500)
                    .pipe(
                        finalize(() => {
                            this.showAlert = false;
                            this._alertConfig = null;
                        }),
                        takeWhile(() => time > 0),
                        takeUntil(this._unsubscribeAll),
                        tap(() => (time -= 1))
                    )
                    .subscribe();
            },
        });
    }

    ngOnInit(): void {
        this.subscribeAlert();
        this.newForm();
        this.getProveedores(null, true); // Método que carga proveedores
    }

    newForm() {
        this.form = new FormGroup({
            proveedor: new FormControl(''),
            asunto: new FormControl('')
        });
    }

    selectProveedor($event: MatAutocompleteSelectedEvent, input: HTMLInputElement): void {
        const selected = $event.option.value;

        if (selected && !this.selectedProveedores.includes(selected)) {
            this.selectedProveedores.push(selected);
        }

        this.form.get('proveedor').setValue('');
        input.value = "";
    }

    removeProveedor(proveedor: IProveedor): void {
        const index = this.selectedProveedores.indexOf(proveedor);
        if (index >= 0) {
            this.selectedProveedores.splice(index, 1);
        }
    }

    onSubmit() {
        if (!this.form.valid) {
            return this.alert.info('Revise Los Datos Ingresados');
        } else {

            const proveedores_emails = this.selectedProveedores.map(p => {
                return p.Mail
            });

            const payload: ICreateEmail  = {
                idpedido: this.data.model.idpedido,
                destinatarios: proveedores_emails,
                asunto: this.form.get('asunto').value,
                contenido: this.editorContent,
            }

            this._enviarEmailModalService.enviarEmail(payload, this._endpointService)
                .subscribe({
                    next: (data) => {
                        if (data) {
                            this.enviadoExitosamente.emit(data);
                            this.onCloseMenu(data)
                        }
                    }
                });
        }
    }

    onCloseMenu(data) {
        this.dialogRef.close(data)
    }

    // ################# Metodos de comunicacion con el service #################

    async getProveedores(option?, addAll = false) {
        const Proveedores = await firstValueFrom(this._enviarEmailModalService.getProveedores(option));
        const proveedores_del_pedido = this.data.model.proveedores.split(',').map(num => parseInt(num));

        new Promise(async (resolve) => {
            this.proveedores = Proveedores.list.filter(p => proveedores_del_pedido.includes(p.Codigo));
            this.filteredProveedores = this.form.get('proveedor').valueChanges.pipe(
                startWith(''),
                map((value) => this._filterProveedores(value)),
            );

            if(addAll){
                this.proveedores.forEach(e => {
                    this.selectedProveedores.push(e);
                })
            }
            resolve(true);
        })
    };

    // ################# Metodo de filtrado para inputs autocomplete #################
    private _filterProveedores(proveedor: IProveedor): IProveedor[] {
        const filterValue = proveedor.toString().toLowerCase();
        return this.proveedores.filter(proveedor => proveedor.Mail.toLowerCase().includes(filterValue));
    }

    // ################# Metodos para obtener textos de input #################

    getTextProveedor(option) {
        return option ? option.Mail : '';
    }

    // ################# Metodos extra #################

    /**
    * Funcion para contar las palabras en las observaciones
    */
    getPalabrasLength(): number {
        const observaciones = this.form.get('observaciones').value;
        return observaciones ? observaciones.length : 0;
    }

    /**
    * 
    * @param $event Archivo
    * @param tipo_imagen 0: imagen principal, 1: imagen secundaria
    */
    setImagen($event: Event, tipo_imagen: number) {
        const imagen_data = ($event.target as HTMLInputElement).files[0];

        let size = Math.round(imagen_data.size / 1024); // Convierto el tamano de la imagen a kilobytes
        let nombre_archivo = imagen_data.name; // Encapsulo el nombre del archivo
        let extension_archivo = nombre_archivo.split('.').pop(); // Obtengo la extension del archivo

        this.getBase64(imagen_data).then((imagen_en_base_64) => {
            // Valido el formato del archivo
            if (!this.validExtension.includes('.' + extension_archivo)) {
                return this.alert.info("Formato de archivo no soportado");
            }

            // Encapsulo los valores que me interesan de la imagen
            let data = {
                base64: imagen_en_base_64.toString().split("base64,")[1],
                name: nombre_archivo,
                size: size,
                extension: extension_archivo
            }

            // Si es de tipo_imagen "principal"...
            if (tipo_imagen === 0) {
                // Guardo la informacion en una variable global
                this.adjuntos = [data, ...this.adjuntos];
            }
        });
    }

    async getBase64(file: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    getFileNameFromUrl(url: string): string {
        // Divide la URL en partes utilizando el carácter "/"
        const parts = url.split('/');
        // El último elemento del array es el nombre del archivo
        const fileName = parts[parts.length - 1];
        return fileName;
    }

    getFileSize(size: number): string {
        const bytes = size;
        const kilobytes = bytes / 1024;
        if (kilobytes < 1024) {
            return kilobytes.toFixed(2) + ' KB';
        } else {
            const megabytes = kilobytes / 1024;
            return megabytes.toFixed(2) + ' MB';
        }
    }

    getFileTitle(file: File): string {
        return file.name + ' (' + this.getFileSize(file.size) + ')';
    }

    /**
     * Metodo parametrizado para eliminar los archivos multimedia segun el momento de persistencia
     * @param tipo Tipo de imagen que se quiere eliminar
     * @param file Archivo a eliminar
     */
    removeFile(tipo: string, file: any) {
        switch (tipo) {
            // Eliminar imagenes secundarias dadas de alta en la operacion actual
            case 'adjuntos':
                {
                    this.adjuntos = this.adjuntos.filter((adjunto) => adjunto.name.toLowerCase() != file.name.toLowerCase());
                }
                break;

            default: {
                console.error('Ocurrió un error al quitar el adjunto.')
            }
        }
    }
}