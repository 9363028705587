import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class FormateadorService {
    constructor(private datePipe: DatePipe) {}

    // Métodos de formateo de entrada
    formatInput(value: string): string {
        // Reemplazar todos los caracteres que no son dígitos o comas
        value = value.replace(/[^\d,]/g, '');
    
        // Si hay más de una coma, mantenemos solo la primera
        const parts = value.split(',');
        if (parts.length > 2) {
            value = parts[0] + ',' + parts.slice(1).join('');
        }
    
        return value;
    }
    
    onNumberInput(control: AbstractControl): void {
        const value = this.formatInput(control.value);
        control.setValue(value, { emitEvent: false });
    }
    
    onPercentageBlur(control: AbstractControl): void {
        const value = control.value;
        if (value !== null && value !== '') {
            const numericValue = parseFloat(value.replace(',', '.'));
            if (!isNaN(numericValue)) {
                control.setValue(this.formatPorcentaje(numericValue), { emitEvent: false });
            }
        }
    }
    
    onCurrencyBlur(control: AbstractControl): void {
        const value = control.value;
        if (value !== null && value !== '') {
            // Reemplazar la coma por un punto para el parseo
            const numericValue = this.parseCurrency(value);
            if (!isNaN(numericValue)) {
                control.setValue(this.formatCurrency(numericValue), { emitEvent: false });
            }
        }
    }

    // Métodos de formateo de porcentaje
    formatPorcentaje(value: number | string | null | undefined): string {
        if (value === null || value === undefined || value === '') {
            return '';
        }
        const numValue = typeof value === 'string' ? parseFloat(value) : value;
        return isNaN(numValue) ? '' : `${numValue}%`;
    }

    parsePorcentaje(value: string): number | null {
        if (!value) return null;
        const numericValue = parseFloat(value.replace('%', ''));
        return isNaN(numericValue) ? null : numericValue;
    }

    // Métodos de formateo de moneda
    formatCurrency(value: number | string | null | undefined): string {
        if (value === null || value === undefined || value === '') {
            return '';
        }
        const numValue = typeof value === 'string' ? parseFloat(value) : value;
        if (isNaN(numValue)) return '';
        
        // Formatear el número en el formato argentino
        const result = new Intl.NumberFormat('es-AR', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(numValue);
        
        return result;
    }

    parseCurrency(value: string): number {
        if (!value) return null;

        // Reemplazar todos los caracteres que no son dígitos, comas o puntos
        const cleanValue = value.replace(/[^\d,]/g, '');

        // Si hay una coma, la reemplazamos por un punto para el parseo
        const numberString = cleanValue.replace(',', '.');

        // Convertir a número
        const result = parseFloat(numberString);
        return isNaN(result) ? null : result;
    }

    // Métodos de formateo de fecha
    formatDate(value: string | Date): string {
        if (!value) return '-';
        return this.datePipe.transform(value, 'dd/MM/yyyy') || '-';
    }

    parseDate(dateString: string): string | null {
        if (!dateString) return null;
        const parts = dateString.split('/');
        if (parts.length !== 3) return null;
        return `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
    }
}
