import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, isDevMode } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withHashLocation, withInMemoryScrolling, withPreloading } from '@angular/router';
import { AuthUtils } from '@core/auth/auth.utils';
import { provideFuse } from '@fuse';
import { TokenService } from '@shared/service/app/token.service';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { provideServiceWorker } from '@angular/service-worker';

export const appConfig: ApplicationConfig = {
    providers: [
    provideAnimations(),
    provideHttpClient(),
    provideRouter(appRoutes, withPreloading(PreloadAllModules), withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }), withHashLocation()),
    // Material Date Adapter
    {
        provide: DateAdapter,
        useClass: LuxonDateAdapter,
    },
    {
        provide: MAT_DATE_FORMATS,
        useValue: {
            parse: {
                dateInput: 'D',
            },
            display: {
                dateInput: 'DDD',
                monthYearLabel: 'LLL yyyy',
                dateA11yLabel: 'DD',
                monthYearA11yLabel: 'LLLL yyyy',
            },
        },
    },
    // Transloco Config
    provideTransloco(),
    // Fuse
    provideAuth(),
    provideIcons(),
    provideFuse({
        fuse: {
            layout: 'dense',
            scheme: 'light',
            screens: {
                sm: '600px',
                md: '960px',
                lg: '1280px',
                xl: '1440px',
            },
            theme: 'theme-brand',
            themes: [
                {
                    id: 'theme-default',
                    name: 'Default',
                },
                {
                    id: 'theme-brand',
                    name: 'Brand',
                },
                {
                    id: 'theme-teal',
                    name: 'Teal',
                },
                {
                    id: 'theme-rose',
                    name: 'Rose',
                },
                {
                    id: 'theme-purple',
                    name: 'Purple',
                },
                {
                    id: 'theme-amber',
                    name: 'Amber',
                },
            ],
        },
    }),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerImmediately'
    })
],
};
