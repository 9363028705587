const $BASE_API = "https://apijedstion.desajeds.com.ar";
const $BASE_REST = "https://apijedstion.desajeds.com.ar/web"; // Desarrollo
// const $BASE_REST = "https://apijedstion.projeds.com"; // Produccion
// const $BASE_REST = "http://localhost/jedstion_backend/web"; // Local

export const environment = {
      production: false,
      baseRest: $BASE_REST,
      login: {
            users: `${$BASE_REST}/view_usuario_logins`,
            login: `${$BASE_REST}/usuario/login`,
            view_usuarios: `${$BASE_REST}/view_usuarios`,
            credencial_usuario: `${$BASE_REST}/view_usuario/generar_credencial`
      },
      administracion: {
            tratamientos: `${$BASE_REST}/tratamientos`,
            select_tratamientos: `${$BASE_REST}/select?modelo=Tratamiento&campo_id=idtratamiento&campo_descripcion=descripcion&campo_activo=activo`,
            bancos: `${$BASE_REST}/fondos_bancos`,
            proveedores: `${$BASE_REST}/proveedors`,
            banco_token: `${$BASE_REST}/fondos_banco_token`,
            tratamiento_token: `${$BASE_REST}/tratamiento_token`,
            proveedor_token: `${$BASE_REST}/proveedor_token`,
            view_proveedor_token: `${$BASE_REST}/view_proveedor_token`,
            view_proveedores: `${$BASE_REST}/view_proveedors`,
            view_ven_pago_tipos: `${$BASE_REST}/view_ven_pago_tipos`,
            ven_pago_tipos: `${$BASE_REST}/ven_pago_tipos`,
            clientes: `${$BASE_REST}/adm_clientes`,
            view_clientes: `${$BASE_REST}/view_clientes`,
            select_clientes: `${$BASE_REST}/select?modelo=Cliente&campo_id=codigo&campo_descripcion=razonSocial&campo_activo=activo`,
            egr_tipo_pagos: `${$BASE_REST}/egr_tipo_pagos`,
            condicion_iva: `${$BASE_REST}/select?modelo=condicioniva&campo_id=codigo&campo_descripcion=descripcion&campo_activo=activo`,
            vta_cobro_tipos: `${$BASE_REST}/vta_cobro_tipos`,
            view_vta_cobro_tipos: `${$BASE_REST}/view_vta_cobro_tipos`,
            estados_ventas: `${$BASE_REST}/com_estados`, 
      },
      fondos: {
            fondos: `${$BASE_REST}/fondos_fondos`,
            fondos_token: `${$BASE_REST}/fondos_fondo_tokens`,
            view_fondos: `${$BASE_REST}/view_fondos`,
            fondos_tipo: `${$BASE_REST}/fondos_tipos`,
            movimientos: `${$BASE_REST}/fondos_movimientos`,
            view_movimientos: `${$BASE_REST}/view_movimientos`
      },
      stock: {
            articulos: `${$BASE_REST}/stk_articulos`,
            articulo_unidad_medidas: `${$BASE_REST}/stk_articulo_unidad_medidas`,
            depositos: `${$BASE_REST}/stk_depositos`,
            entregas: `${$BASE_REST}/stk_entregas`,
            unidad_medidas: `${$BASE_REST}/stk_unidad_medidas`,
            movimiento_conversion: `${$BASE_REST}/stk_movimiento_conversions`,
            movimiento_internos: `${$BASE_REST}/stk_movimiento_internos`,
            remitos: `${$BASE_REST}/stk_remitos`,
            rubros: `${$BASE_REST}/stk_rubros`,
            reporte_etiqueta: `${$BASE_REST}/stk_articulo/generar_etiquetas`,
            reporte_entrega: `${$BASE_REST}/stk_entrega/generar_reporte?identrega=`,
            reporte_movimiento_internos: `${$BASE_REST}/stk_movimiento_interno/generar_reporte?idmovimientointerno=`,
            stock_inicial: `${$BASE_REST}/stk_stock_inicials`,
            ver_imagen: `${$BASE_REST}`, // uploads/nombre_imagen se lo concatena en otros componentes
            view_articulos: `${$BASE_REST}/view_articulos`,
            view_stock_inicials: `${$BASE_REST}/view_stock_inicials`,
            view_stock_movimientos: `${$BASE_REST}/view_stock_movimientos`,
            ver_reporte_movimiento_internos: `${$BASE_REST}/`,
            view_stock_articulo_depositos: `${$BASE_REST}/view_stock_articulo_depositos`,
            view_items_oc_pendientes: `${$BASE_REST}/view_items_oc_pendientes`,
            view_stock_entregas: `${$BASE_REST}/view_stock_entregas`,
            view_stock_depositos: `${$BASE_REST}/view_stock_depositos`,
            entregar: `${$BASE_REST}/stk_entrega/entregar?identrega=`,
            stk_entrega_puede_crear: `${$BASE_REST}/stk_entrega/puede_crear`,
            stk_articulo_adjuntos: `${$BASE_REST}/stk_articulo/adjuntos`,
            stk_articulo_obtener_adjuntos: `${$BASE_REST}/stk_articulo/obtener_adjuntos`,
            stk_articulo_ordenar: `${$BASE_REST}/stk_articulo/ordenar`
      },
      ventas: {
            vta_ventas: `${$BASE_REST}/vta_ventas`,
            vta_venta_calcular_item: `${$BASE_REST}/vta_venta/calcular_item`,
            vta_venta_calcular_unitario: `${$BASE_REST}/vta_venta/calcular_unitario`,
            vta_venta_get_datos: `${$BASE_REST}/vta_venta/get_datos`,
            view_vta_ventas: `${$BASE_REST}/view_vta_ventas`,
            view_vta_venta_bonificado_detalles: `${$BASE_REST}/view_vta_venta_bonificado_detalles`,
            view_vta_venta_selects: `${$BASE_REST}/view_vta_venta_selects`,
            view_venta_selects: `${$BASE_REST}/view_venta_selects`,
            view_venta_genericas: `${$BASE_REST}/view_venta_genericas`,
            vta_cobro_tipo: `${$BASE_REST}/vta_cobro_tipo`,
            calcular_importe: `${$BASE_REST}/vta_cobro/calcular_importe`,
            cargar_item_cobro_calculadora: `${$BASE_REST}/vta_cobro/cargar_item_cobro_calculadora`,
            generar_reporte_calculadora: `${$BASE_REST}/vta_cobro/generar_reporte_calculadora`,
            generar_reporte_cobro: `${$BASE_REST}/vta_cobro/generar_reporte_cobro` ,
            view_vta_cobros: `${$BASE_REST}/view_vta_cobro`,
            view_cliente: `${$BASE_REST}/view_cliente`,
            view_vta_venta_pendiente: `${$BASE_REST}/view_vta_venta_pendiente`,
            fondos_bancos: `${$BASE_REST}/fondos_bancos`,
            calcular_interes: `${$BASE_REST}/vta_cobro/calcular_interes`,
            cargar_item_cobro: `${$BASE_REST}/vta_cobro/cargar_item_cobro`,
            cargar_forma_cobro: `${$BASE_REST}/vta_cobro/cargar_forma_cobro`,
            vta_cobros: `${$BASE_REST}/vta_cobros`,
            get_datos: `${$BASE_REST}/vta_cobro/get_datos`,
            view_vta_venta_estados: `${$BASE_REST}/view_vta_venta_estados`,
            vta_venta_estados: `${$BASE_REST}/vta_venta_estados`
      },
      compras: {
            compras_cotizacion: `${$BASE_REST}/compras_cotizacion`,
            compras_pedidos: `${$BASE_REST}/compras_pedidos`,
            compras_pedidos_enviar_presupuesto: `${$BASE_REST}/compras_pedido/enviar_presupuesto`,
            generar_reporte_pedido: `${$BASE_REST}/compras_pedido/generar_reporte_pedido?idpedido=`,
            view_compras_pedidos: `${$BASE_REST}/view_compras_pedidos`,
            view_compras_pedido_item: `${$BASE_REST}/view_compras_pedido_item`,
            compras_orden_compra: `${$BASE_REST}/compras_orden_compras`,
            generar_reporte_orden_compra: `${$BASE_REST}/compras_orden_compra/generar_reporte_orden_compra?idordencompra=`,
            compras_orden_compra_items: `${$BASE_REST}/compras_orden_compra_items`,
            compras_orden_compra_cotizacion: `${$BASE_REST}/compras_orden_compra/cotizacion`,
            view_orden_compras: `${$BASE_REST}/view_orden_compras`,
            view_compras_cotizacion: `${$BASE_REST}/view_compras_cotizacion`,
            view_compras_cotizacion_item: `${$BASE_REST}/view_compras_cotizacion_item`,
      },
      localidades: `${$BASE_REST}/com_localidads`,
      controlador: `${$BASE_API}/controlador`,
      menu: `${$BASE_REST}/com_menus`,
      empresa: {
            api: `${$BASE_REST}/com_menus`,
            uploads: `${$BASE_REST}`,
      },
      notificaciones: {
            view_notificaciones: `${$BASE_REST}/view_notificacions`,
            view_notificaciones_group: `${$BASE_REST}/view_notificacion_group`
      },
      inmuebles: {
            complejos: {
                  inm_complejos: `${$BASE_REST}/inm_complejos`,
                  view_inm_complejos: `${$BASE_REST}/view_inm_complejos`
            },
            unidad_funcional: {
                  inm_unidad_funcional: `${$BASE_REST}/inm_unidad_funcional`,
                  view_inm_unidad_funcional: `${$BASE_REST}/view_inm_unidad_funcionals`
            }
      },
      reporte_orden_servicio: `${$BASE_REST}/generar_orden_servicios`
};
