<!-- Notifications toggle -->
<button
    mat-icon-button
    (click)="openPanel()"
    #notificationsOrigin>
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span [class]="color_primario ? '' : default_color"
                [style.background]="color_primario" class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full text-indigo-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-96 sm:w-96 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div [@expandCollapse]="'expanded'" [class]="color_primario ? '' : default_color"
            [style.background]="color_primario"
            class="flex shrink-0 items-center py-4 pr-4 pl-6 text-on-primary">
            <svg class="icon-size-7 mr-4" width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.8069 5.68699C20.1049 5.93199 20.3529 6.23699 20.5339 6.58399C20.5602 6.63724 20.5653 6.69839 20.5485 6.75529C20.5316 6.81218 20.4939 6.86063 20.4429 6.89099L14.1769 10.771C13.5175 11.1793 12.7588 11.3993 11.9832 11.4073C11.2076 11.4152 10.4446 11.2107 9.77694 10.816L3.46994 7.08799C3.42023 7.05989 3.38239 7.0147 3.36345 6.96082C3.34451 6.90695 3.34575 6.84803 3.36694 6.79499C3.54804 6.36534 3.83041 5.98587 4.18994 5.68899V5.68599L4.20194 5.67899C4.46822 5.46202 4.77092 5.29408 5.09594 5.18299L9.20594 2.89899C10.0602 2.42442 11.0212 2.17537 11.9984 2.17537C12.9756 2.17537 13.9367 2.42442 14.7909 2.89899L18.8959 5.17899C19.2299 5.29299 19.5369 5.46499 19.8039 5.68399L19.8069 5.68699Z" />
                <path d="M2.98899 8.95399C2.9934 8.9127 3.00812 8.87317 3.03178 8.83904C3.05545 8.80491 3.08731 8.77728 3.12443 8.75866C3.16156 8.74005 3.20277 8.73106 3.24427 8.73251C3.28578 8.73397 3.32626 8.74583 3.36199 8.76699L9.01499 12.107C9.91812 12.6406 10.95 12.917 11.999 12.9063C13.0479 12.8955 14.074 12.598 14.966 12.046L20.611 8.55099C20.6465 8.52898 20.6871 8.51631 20.7288 8.51417C20.7706 8.51202 20.8122 8.52047 20.8499 8.53874C20.8875 8.557 20.9199 8.58447 20.944 8.61861C20.9682 8.65275 20.9833 8.69244 20.988 8.73399C21.2684 11.3235 21.2143 13.9383 20.827 16.514C20.7304 17.1552 20.4208 17.7453 19.9481 18.1892C19.4754 18.633 18.867 18.9049 18.221 18.961L16.711 19.092C13.5762 19.3645 10.4237 19.3645 7.28899 19.092L5.77899 18.961C5.13281 18.9049 4.52435 18.6328 4.0516 18.1888C3.57886 17.7447 3.26937 17.1544 3.17299 16.513C2.79682 14.0108 2.73501 11.4715 2.98899 8.95399Z"/>
            </svg>                    
            <div class="text-lg font-medium leading-10">Notificaciones</div>
            <div class="absolute top-4 right-4">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="text-white">close</mat-icon>
                </button>
            </div>
            <!-- <mat-slide-toggle class="ml-auto">
                <span class="text-xs">Mostrar solo las no leídas</span>
            </mat-slide-toggle> -->
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Notifications Group -->
            <div *ngIf="!showNotifications">
                <ng-container *ngFor="let notification of notifications_group; trackBy: trackByFn">
                    <div (click)="verMasNotificaciones(notification)"
                        class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                        [ngClass]="{'unread': !notification.read}">
    
                        <!-- Notification with a link -->
                        <ng-container *ngIf="notification.link">
                            <!-- Normal links -->
                            <ng-container *ngIf="!notification.useRouter">
                                <a
                                    class="flex flex-auto py-5 pl-6 cursor-pointer"
                                    [routerLink]="notification.link"
                                    (click)="closePanel()">
                                    <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                                </a>
                            </ng-container>
                            <!-- Router links -->
                            <ng-container *ngIf="notification.useRouter">
                                <a
                                    class="flex flex-auto py-5 pl-6 cursor-pointer"
                                    [routerLink]="notification.link">
                                    <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                                </a>
                            </ng-container>
                        </ng-container>
    
                        <!-- Notification without a link -->
                        <ng-container *ngIf="!notification.link">
                            <div class="flex flex-auto py-5 pl-6">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </div>
                        </ng-container>
    
                        <!-- Actions -->
                        <div class="relative flex flex-col my-5 mr-6 ml-2">
                            <!-- Indicator -->
                            <button
                                class="w-6 h-6 min-h-6"
                                mat-icon-button
                                (click)="toggleRead(notification)"
                                matTooltip="Marcar como leída">
                                <span
                                    class="w-2 h-2 rounded-full"
                                    [class]="color_primario ? '' : default_color"
                                    [style.background]="color_primario"
                                    [ngClass]="{'bg-gray-400 sm:opacity-0 sm:group-hover:opacity-100': notification.read,
                                                color_primario : !notification.read}"></span>
                            </button>
                            <!-- Group -->
                            <button 
                                class="w-6 h-6 min-h-6 mt-1" 
                                mat-icon-button
                                matTooltip="Ver más notificaciones"
                                (click)="verMasNotificaciones(notification)">
                                <mat-icon [class]="color_primario ? '' : default_color"
                                    [style.fill]="color_primario" 
                                    class="icon-size-2"
                                    svgIcon="keyboard_arrow_down">                                
                                </mat-icon>
                            </button>
                            <!-- Remove -->
                            <!-- <button
                                class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"
                                mat-icon-button
                                (click)="delete(notification)"
                                [matTooltip]="'Remove'">
                                <mat-icon
                                    class="icon-size-4"
                                    [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                            </button> -->
                        </div>
    
                    </div>
    
                    <!-- Notification content template -->
                    <ng-template #notificationContent>
                        <!-- Icon -->
                        <ng-container *ngIf="notification.icon && !notification.image">
                            <div [class]="color_primario ? '' : default_color"
                                [style.background]="color_primario"
                                class="flex shrink-0 items-center justify-center w-12 h-12 mr-4 rounded-md dark:bg-gray-700">
                                <mat-icon
                                    class="icon-size-7 text-white"
                                    [svgIcon]="notification.icon">
                                </mat-icon>
                            </div>
                        </ng-container>
                        <!-- Image -->
                        <ng-container *ngIf="notification.image">
                            <img
                                class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                                [src]="notification.image"
                                [alt]="'Notification image'">
                        </ng-container>
                        <!-- Title, description & time -->
                        <div class="flex flex-col flex-auto">
                            <ng-container *ngIf="notification.title">
                                <div class="flex justify-between mb-2">
                                    <div
                                        class="font-semibold line-clamp-1"
                                        [innerHTML]="notification.title">
                                    </div>
                                    <div class="text-sm leading-none text-secondary mt-1">
                                        {{notification.date | date:'dd MMMM'}}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="notification.description">
                                <div
                                    class="line-clamp-2"
                                    [innerHTML]="notification.description"></div>
                            </ng-container>
                        </div>
                    </ng-template>
                </ng-container>
            </div>

            <!-- Notifications -->
            <div *ngIf="showNotifications">
                <button 
                    class="w-6 h-6 min-h-6 mt-1 ml-5" 
                    mat-icon-button
                    matTooltip="Volver atrás"
                    (click)="showNotifications = false">
                    <mat-icon [class]="color_primario ? '' : default_color"
                        [style.fill]="color_primario" 
                        class="icon-size-2"
                        svgIcon="keyboard_arrow_left">                                
                    </mat-icon>
                </button>
                <ng-container *ngFor="let notification of notifications; trackBy: trackByFn">
                    <div [@expandCollapse]="'expanded'" 
                        class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                        [ngClass]="{'unread': !notification.read}">
    
                        <!-- Notification with a link -->
                        <ng-container *ngIf="notification.link">
                            <!-- Normal links -->
                            <ng-container *ngIf="!notification.useRouter">
                                <a
                                    class="flex flex-auto py-5 pl-6 cursor-pointer"
                                    [routerLink]="notification.link"
                                    (click)="closePanel()">
                                    <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                                </a>
                            </ng-container>
                            <!-- Router links -->
                            <ng-container *ngIf="notification.useRouter">
                                <a
                                    class="flex flex-auto py-5 pl-6 cursor-pointer"
                                    [routerLink]="notification.link">
                                    <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                                </a>
                            </ng-container>
                        </ng-container>
    
                        <!-- Notification without a link -->
                        <ng-container *ngIf="!notification.link">
                            <div class="flex flex-auto py-5 pl-6">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </div>
                        </ng-container>
    
                        <!-- Actions -->
                        <div class="relative flex flex-col my-5 mr-6 ml-2">
                            <!-- Indicator -->
                            <button
                                class="w-6 h-6 min-h-6"
                                mat-icon-button
                                (click)="toggleRead(notification)"
                                matTooltip="Marcar como leída">
                                <span
                                    class="w-2 h-2 rounded-full"
                                    [class]="color_primario ? '' : default_color"
                                    [style.background]="color_primario"
                                    [ngClass]="{'bg-gray-400 sm:opacity-0 sm:group-hover:opacity-100': notification.read,
                                                color_primario : !notification.read}"></span>
                            </button>
                            <!-- Remove -->
                            <!-- <button
                                class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"
                                mat-icon-button
                                (click)="delete(notification)"
                                [matTooltip]="'Remove'">
                                <mat-icon
                                    class="icon-size-4"
                                    [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                            </button> -->
                        </div>
    
                    </div>
    
                    <!-- Notification content template -->
                    <ng-template #notificationContent>
                        <!-- Icon -->
                        <ng-container *ngIf="notification.icon && !notification.image">
                            <div [class]="color_primario ? '' : default_color"
                                [style.background]="color_primario"
                                class="flex shrink-0 items-center justify-center w-12 h-12 mr-4 rounded-md dark:bg-gray-700">
                                <mat-icon
                                    class="icon-size-7 text-white"
                                    [svgIcon]="notification.icon">
                                </mat-icon>
                            </div>
                        </ng-container>
                        <!-- Image -->
                        <ng-container *ngIf="notification.image">
                            <img
                                class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                                [src]="notification.image"
                                [alt]="'Notification image'">
                        </ng-container>
                        <!-- Title, description & time -->
                        <div class="flex flex-col flex-auto">
                            <ng-container *ngIf="notification.title">
                                <div class="flex justify-between mb-2">
                                    <div
                                        class="font-semibold line-clamp-1"
                                        [innerHTML]="notification.title">
                                    </div>
                                    <div class="text-sm leading-none text-secondary mt-1">
                                        {{notification.date | date:'dd MMMM'}}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="notification.description">
                                <div
                                    class="line-clamp-2"
                                    [innerHTML]="notification.description"></div>
                            </ng-container>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
            
            <!-- No notifications -->
            <ng-container *ngIf="!notifications_group || !notifications_group.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div [class]="color_primario ? '' : default_color" [style.background-color]="color_primario"
                        class="flex flex-0 items-center justify-center w-14 h-14 rounded-full">
                        <mat-icon
                            class="text-white"
                            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">
                        No tienes notificaciones
                    </div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
                        Cuando tengas notificaciones se mostraran aquí.
                    </div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>
