/* eslint-disable */

import { FuseNavigationItem } from "@fuse/components/navigation";

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Home',
        type: 'basic',
        icon: 'home',
        link: '/example'
    },
    {
        id: 'administracion',
        title: 'Administración',
        type: 'collapsable',
        link: '/administracion',
        icon: 'admin_panel_settings',
        children: [
            {
                id: 'bancos',
                title: 'Bancos',
                type: 'basic',
                link: '/bancos',
            },
            {
                id: 'clientes',
                title: 'Clientes',
                type: 'basic',
                link: '/clientes',
            },
            {
                id: 'medios_de_cobro',
                title: 'Medios de cobro',
                type: 'basic',
                link: '/medios-de-cobro',
            },
            {
                id: 'medios_de_pago',
                title: 'Medios de pago',
                type: 'basic',
                link: '/medios-de-pago',
            },
            {
                id: 'proveedores',
                title: 'Proveedores',
                type: 'basic',
                link: '/proveedores',
            },
            {
                id: 'tratamientos',
                title: 'Tratamientos',
                type: 'basic',
                link: '/tratamientos',
            },
        ]
    },
    {
        id: 'compras',
        title: 'Compras',
        type: 'collapsable',
        link: '/compras',
        icon: 'shopping_cart',
        children: [
            {
                id: 'pedidos_de_presupuesto',
                title: 'Pedidos de presupuesto',
                type: 'basic',
                link: '/pedidos-de-presupuesto'
            },
            {
                id: 'ordenes_de_compra',
                title: 'Ordenes de compra',
                type: 'basic',
                link: '/ordenes-de-compra'
            }
        ]
    },
    {
        id: 'fondos',
        title: 'Fondos',
        type: 'collapsable',
        link: '/fondos',
        icon: 'account_balance',
        children: [
            {
                id: 'fondos',
                title: 'Fondos',
                type: 'basic',
                link: '/fondos',
            },
            {
                id: 'movimientos',
                title: 'Movimientos',
                type: 'basic',
                link: '/movimientos',
            },
            {
                id: 'tipo-de-movimientos',
                title: 'Tipo de movimientos',
                type: 'basic',
                link: '/tipo-de-movimientos',
            },
        ]
    },
    {
        id: 'stock',
        title: 'Stock',
        type: 'collapsable',
        link: '/stock',
        icon: 'auto_awesome_motion',
        children: [
            {
                id: 'articulos',
                title: 'Artículos',
                type: 'basic',
                link: '/articulos',
            },
            {
                id: 'depositos',
                title: 'Depósitos',
                type: 'basic',
                link: '/depositos',
            },
            {
                id: 'entregas',
                title: 'Entregas',
                type: 'basic',
                link: '/entregas',
            },
            {
                id: 'remitos',
                title: 'Remitos',
                type: 'basic',
                link: '/remitos',
            },
            {
                id: 'rubros',
                title: 'Rubros',
                type: 'basic',
                link: '/rubros',
            },
            {
                id: 'unidades_de_medida',
                title: 'Unidades de medida',
                type: 'basic',
                link: '/unidades-de-medida',
            },
            {
                id: 'stock_inicial',
                title: 'Stock Inicial',
                type: 'basic',
                link: '/stock-inicial',
            },
            {
                id: 'movimientos_internos',
                title: 'Movimientos internos',
                type: 'basic',
                link: '/movimientos-internos',
            },
            {
                id: 'movimiento_conversion',
                title: 'Movimiento conversion',
                type: 'basic',
                link: '/movimiento-conversion',
            },

        ]
    },


];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
