import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable, Subject } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';
import { IResponse, Response } from '@shared/models/response-with-token';
import { IProveedor } from 'app/modules/administracion/proveedores/models/proveedor';

@Injectable()
export class EnviarEmailModalService {
    constructor(private http: HttpClient, private _authService: AuthService) { }

    getProveedores(option?) {
        let f = '?sort=RazonSocial';
        f += !option ? '&filter[activo]=1' : '';
        let subject = new Subject<any>();
        this.http.get<Response[]>(`${environment.administracion.proveedores}${f}`)
            .subscribe((resp: any) => {
                // this._authService.accessToken = resp.token;
                subject.next({
                    list: resp
                })
            });
        return subject.asObservable();
    }

    enviarEmail(payload, _endpointService: string): Observable<any> {
        return this.http.post<any>(_endpointService, payload);
    }
}