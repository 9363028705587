import { Route } from '@angular/router';
import { initialDataResolver } from './app.resolvers';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { LayoutComponent } from './layout/layout.component';
import { PermisoGuard } from '@core/auth/guards/permiso.guard';
import { ReporteComponent } from './shared/components/reportes/reporte.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'example' },

    // Redirect 'denegado' path to '/acceso-denegado'
    { path: 'acceso-denegado', pathMatch: 'full', redirectTo: 'acceso-denegado' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'example' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('./modules/auth/confirmation-required/confirmation-required.routes') },
            { path: 'forgot-password', loadChildren: () => import('./modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password', loadChildren: () => import('./modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('./modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('./modules/auth/sign-up/sign-up.routes') }
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('./modules/auth/sign-out/sign-out.routes') },
            { path: 'acceso-denegado', loadChildren: () => import('./modules/admin/acceso-denegado/acceso-denegado.routes') },
            {
                path: 'example',
                loadChildren: () => import('./modules/admin/example/example.routes')
            },
            {
                path: 'bancos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/administracion/bancos/bancos.module').then(m => m.BancosModule)
            },
            {
                path: 'tratamientos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/administracion/tratamientos/tratamientos.module').then(m => m.TratamientosModule)
            },
            {
                path: 'proveedores',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/administracion/proveedores/proveedores.module').then(m => m.ProveedoresModule)
            },
            {
                path: 'fondos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/fondos/fondos/fondos.module').then(m => m.FondosModule)
            },
            {
                path: 'tipo-de-movimientos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/fondos/tipo-movimientos/tipo-movimientos.module').then(m => m.TipoMovimientosModule)
            },
            {
                path: 'movimientos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/fondos/movimientos/movimientos.module').then(m => m.MovimientosModule)
            },
            {
                path: 'medios-de-cobro',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/administracion/medios-de-cobro/medios-de-cobro.module').then(m => m.MediosDeCobroModule)
            },
            {
                path: 'unidades-de-medida',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/stock/unidades-de-medida/unidades-de-medida.module').then(m => m.UnidadesDeMedidaModule)
            },
            {
                path: 'depositos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/stock/depositos/depositos.module').then(m => m.DepositosModule)
            },
            {
                path: 'rubros',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/stock/rubros/rubros.module').then(m => m.RubrosModule)
            },
            {
                path: 'articulos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/stock/articulos/articulos.module').then(m => m.ArticulosModule)
            },
            {
                path: 'clientes',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/administracion/clientes/clientes.module').then(m => m.ClientesModule)
            },
            {
                path: 'medios-de-pago',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/administracion/medios-de-pago/medios-de-pago.module').then(m => m.MediosDePagoModule)
            },
            {
                path: 'pedidos-de-presupuesto',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/compras/pedidos-de-presupuesto/pedidos-de-presupuestos.module').then(m => m.PedidosDePresupuestoModule)
            },
            {
                path: 'stock-inicial',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/stock/stock-inicial/stock-inicial.module').then(m => m.StockInicialModule)
            },
            {
                path: 'ordenes-de-compra',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/compras/ordenes-de-compra/ordenes-de-compra.module').then(m => m.OrdenesDeCompraModule)
            },
            {
                path: 'movimientos-internos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/stock/movimientos-internos/movimientos-internos.module').then(m => m.MovimientosInternosModule)
            },
            {
                path: 'movimiento-conversion',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/stock/movimiento-conversion/movimiento-conversion.module').then(m => m.MovimientoConversionModule)
            },
            {
                path: 'remitos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/stock/remitos/remitos.module').then(m => m.RemitosModule)
            },
            {
                path: 'entregas',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/stock/entregas/entregas.module').then(m => m.EntregasModule)
            },
            {
                path: 'usuarios',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/seguridad/usuarios/usuarios.module').then(m => m.UsuariosModule)
            },
            {
                path: 'ventas',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/ventas/genericas/venta-generica.module').then(m => m.VentaGenericasModule)
            },
            {
                path: 'entrega_qr',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/stock/entregas-qr/entregas-qr.module').then(m => m.EntregasQRModule)
            },
            {
                path: 'reporte/:path',
                component: ReporteComponent
            },
            {
                path: 'complejos',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/inmuebles/complejos/complejos.module').then(m => m.ComplejosModule)
            },
            {
                path: 'unidades-funcionales',
                canActivate: [PermisoGuard],
                loadChildren: () => import('./modules/inmuebles/unidades-funcionales/unidades-funcionales.module').then(m => m.UnidadesFuncionalesModule)
            },
        ]
    }
    // Auth routes for authenticated users
    // {
    //     path: '',
    //     canActivate: [AuthGuard],
    //     canActivateChild: [AuthGuard],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
    //         { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') },
    //         {
    //             path: 'example',
    //             loadChildren: () => import('app/modules/admin/example/example.routes')
    //         },
    //         {
    //             path: 'bancos',
    //             loadChildren: () => import('app/modules/administracion/bancos/bancos.module').then(m => m.BancosModule)
    //         },
    //         {
    //             path: 'tratamientos',
    //             loadChildren: () => import('app/modules/administracion/tratamientos/tratamientos.module').then(m => m.TratamientosModule)
    //         },
    //         {
    //             path: 'proveedores',
    //             loadChildren: () => import('app/modules/administracion/proveedores/proveedores.module').then(m => m.ProveedoresModule)
    //         },
    //         {
    //             path: 'fondos',
    //             loadChildren: () => import('app/modules/fondos/fondos/fondos.module').then(m => m.FondosModule)
    //         },
    //         {
    //             path: 'tipo-de-movimientos',
    //             loadChildren: () => import('app/modules/fondos/tipo-movimientos/tipo-movimientos.module').then(m => m.TipoMovimientosModule)
    //         },
    //         {
    //             path: 'movimientos',
    //             loadChildren: () => import('app/modules/fondos/movimientos/movimientos.module').then(m => m.MovimientosModule)
    //         },
    //         {
    //             path: 'medios-de-cobro',
    //             loadChildren: () => import('app/modules/administracion/medios-de-cobro/medios-de-cobro.module').then(m => m.MediosDeCobroModule)
    //         },
    //         {
    //             path: 'unidades-de-medida',
    //             loadChildren: () => import('app/modules/stock/unidades-de-medida/unidades-de-medida.module').then(m => m.UnidadesDeMedidaModule)
    //         },
    //         {
    //             path: 'depositos',
    //             loadChildren: () => import('app/modules/stock/depositos/depositos.module').then(m => m.DepositosModule)
    //         },
    //         {
    //             path: 'rubros',
    //             loadChildren: () => import('app/modules/stock/rubros/rubros.module').then(m => m.RubrosModule)
    //         },
    //         {
    //             path: 'articulos',
    //             loadChildren: () => import('app/modules/stock/articulos/articulos.module').then(m => m.ArticulosModule)
    //         },
    //     ]
    // },



    // // Landing routes
    // {
    //     path: '',
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
    //     ]
    // },


];