import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { NavigationService } from '@core/navigation/navigation.service';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, throwError } from 'rxjs';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const navigationService = inject(NavigationService);

    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
        newReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken),
        });
        if (navigationService._navigationMenuData.length == 0) {
            navigationService.getMenus();
        }
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            //Capta errores desconocidos
            if (error.status === 0) {
                authService.signOut()
                //Reload tge app
                location.reload()
                return throwError(() => new Error('Token expirado u inexistente'));                //Sign out
            }

            //Capta errores desconocidos
            if (error.status === 500) {
                if (error.error.code == 23000) {
                    return throwError(() => new Error('No puede eliminar un item que tiene elementos asociados.'));
                }
                authService.signOut()
                //Reload tge app
                location.reload()
                return throwError(() => new Error('Error interno del servidor'));                //Sign out
            }


            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                authService.signOut();

                // Reload the app
                location.reload();
                return throwError(() => new Error('Credenciales invalidas.'));
            }



        }),
    );
};
