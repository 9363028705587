<div
    class="fixed inset-0 sm:static max-h-screen h-screen sm:inset-auto flex flex-col max-w-[1280px] xl:max-w-[1280px] xl:min-w-[950px] sm:max-w-[950px] sm:min-w-[800px] sm:rounded-2xl overflow-y-auto shadow-lg bg-white dark:bg-slate-900">
    <div [class]="color_primario ? '' : default_color" [style.background-color]="color_primario || '' "
        class="w-full px-4 py-4 flex justify-between items-center">
        <span class="text-white font-bold text-lg">{{title}}</span>
        <div>
            <button mat-icon-button (click)="onCloseMenu(null)">
                <mat-icon class="text-white">close</mat-icon>
            </button>
        </div>
    </div>
    <!-- Alert -->
    <fuse-alert class="fixed top-0 left-0 w-full flex justify-center items-center mt-8 alert-main z-99999"
        [type]="_alertConfig.type" [appearance]="'outline'" *ngIf="showAlert">
        {{ _alertConfig.message }}
    </fuse-alert>
    <div class="flex flex-col w-full h-full px-6 py-2">
        <form class="form-menu" [formGroup]="form">
            <mat-form-field appearance='outline' class="w-full sm:w-full min-h-2">
                <mat-label>Proveedor</mat-label>
                <mat-chip-grid #chipgrid aria-label="Seleccione Proveedor"
                    class="sm:max-h-[50px] xl:max-h-[200px] max-h-[100px] overflow-y-auto flex flex-wrap gap-2 p-2">
                    <mat-chip-row *ngFor="let proveedor of selectedProveedores" [value]="proveedor"
                        (removed)="removeProveedor(proveedor)">
                        {{ proveedor.Mail }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    <input [placeholder]=" 'Agregar Proveedor' " #proveedorInput formControlName="proveedor"
                        [matChipInputFor]="chipgrid" [matAutocomplete]="auto" />
                </mat-chip-grid>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectProveedor($event, proveedorInput)">
                    <mat-option *ngFor=" let proveedor of filteredProveedores | async" [value]="proveedor" (mouseenter)="onHover(true, $event)"
                    (mouseleave)="onHover(false, $event)">
                        {{ proveedor.RazonSocial }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance='outline' class="w-full sm:w-full">
                <mat-label>Asunto</mat-label>
                <input matInput type="text" formControlName="asunto">
            </mat-form-field>
        </form>

        <div class="w-full sm:w-full">
            <quill-editor [(ngModel)]="editorContent" [style]="{ height: '150px', width: '100%' }"
                [modules]="editorModules" [placeholder]="'Escribe algo aquí ...'">
            </quill-editor>
        </div>

        <!-- Sección: Adjuntos -->
        <div *ngIf="permiteAdjunto" class="mt-2 w-full">
            <div class="flex items-center gap-2">
                <button mat-icon-button type="button" matTooltip="Añadir Imagen"
                    [class]="color_primario ? '' : default_color" [style.background-color]="color_primario || '' "
                    (click)="fileInput.click();">
                    <mat-icon class="text-white transform -rotate-45">attach_file</mat-icon>
                </button>
                <input #fileInput id="fileToUploadImagen" type="file" [accept]="validExtension"
                    (change)="setImagen($event, 0)" class="hidden">
                <small *ngIf="adjuntos.length === 0" class="text-muted text-lg cursor-pointer"
                    (click)="fileInput.click()">
                    <b>{{ 'Adjuntos' }}</b> (Max: 20Mb)
                </small>
                <!-- Archivos Nuevos / Guardados -->
                <div *ngIf="adjuntos.length > 0"
                    class="mt-4 grid gap-2 p-2 bg-slate-200 rounded-lg max-w-full max-h-56 xl:max-h-56 sm:max-h-18 overflow-y-auto"
                    style="grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));">
                    <ng-container *ngFor="let imagen of adjuntos">
                        <div [class]="color_primario ? '' : default_color"
                            [style.background-color]="color_primario || '' "
                            class="flex items-center gap-2 px-4 py-2 rounded-lg text-white">
                            <!-- Aquí agrego la clase truncate -->
                            <span class="truncate max-w-xs">{{ imagen.name }} ({{ getFileSize(imagen.size)
                                }})</span>
                            <button mat-icon-button (click)="removeFile('adjuntos', imagen)">
                                <mat-icon class="text-white">close</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>

        </div>
    </div>

    <div class="w-full md:flex md:justify-center md:pb-6 md:px-6 mt-auto">
        <button mat-raised-button [class]="color_primario ? '' : default_color"
            [style.background-color]="color_primario || '' "
            class='w-full rounded-none md:w-36 md:rounded-lg py-8 md:py-0' (click)='onSubmit()'>
            <span class="text-white font-medium text-base">Enviar</span>
        </button>
    </div>
</div>