import { ElementRef, Injectable, Injector } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { IMenuPosition } from '@shared/constants/menu-positions';
import { Observable, of, switchMap } from 'rxjs';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';

@Injectable()
export class JedsMenuService {
    private _overlayRef: OverlayRef;
    private _menuRef: JedsMenuRef;
    constructor(private _overlay: Overlay) { }

    /**
     *
     * @param component Componente a renderizar <ComponentType<any>>
     * @param elementRef Elemento de origen para renderizado <ElementRef>
     * @param data Data a enviar al overlay <any>
     * @returns
     */
    open(component: ComponentType<any>, elementRef: ElementRef, data?: any) {
        this._overlayRef = this.getMenuRef(elementRef);
        this._overlayRef.attach(
            new ComponentPortal(component, null, Injector.create({ providers: [{ provide: 'MENU_DATA', useValue: data }] }))
        );
        this._menuRef = new JedsMenuRef(this._overlayRef);
        return this._menuRef;
    }

    close(data?: any) {
        this._menuRef.close(data);
    }

    /**
     *
     * @param elementRef Elemento de origen para renderizado <ElementRef>
     * @returns Creacion de Overlay <OverlayRef>
     */
    private getMenuRef(elementRef: ElementRef) {
        let position: ConnectedPosition[];
        const init = elementRef.nativeElement.getBoundingClientRect();
        if (init.x < window.innerHeight / 2) {
            position = init.x < window.innerWidth / 2 ? IMenuPosition.topLeft : IMenuPosition.topRight;
        } else {
            position = init.x < window.innerWidth / 2 ? IMenuPosition.bottomLeft : IMenuPosition.bottomRight;
        }
        return this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                // eslint-disable-next-line no-underscore-dangle
                .flexibleConnectedTo(elementRef.nativeElement)
                // .withLockedPosition(true)
                .withPush(true)
                .withPositions(position),
        })
    }
}

class JedsMenuRef extends JedsMenuService {
    private overlayRef: OverlayRef;
    private data: any;
    constructor(overlayRef: OverlayRef, private overlay?: Overlay) {
        super(overlay);
        this.overlayRef = overlayRef;
        this.overlayRef.backdropClick().subscribe({
            next: () => {
                this.overlayRef.detach();
            },
        });
    }

    afterClosed() {
        return this.overlayRef.detachments().pipe(switchMap(() => of(this.data)));
    }

    close(data: any) {
        this.data = data;
        this.overlayRef.detach();
    }
}