
export const TitleType = [
  { icon: "", text: "Inicio", path: "/example" },
  { icon: "", text: "Artículos", path: "/articulos" },
  { icon: "", text: "Bancos", path: "/bancos" },
  { icon: "", text: "Clientes", path: "/clientes" },
  { icon: "", text: "Dépositos", path: "/depositos" },
  { icon: "", text: "Entregas", path: "/entregas" },
  { icon: "", text: "Fondos", path: "/fondos" },
  { icon: "", text: "Medios de cobro", path: "/medios-de-cobro" },
  { icon: "", text: "Estados de Ventas", path: "/estados" },
  { icon: "", text: "Medios de pago", path: "/medios-de-pago" },
  { icon: "", text: "Movimientos", path: "/movimientos" },
  { icon: "", text: "Movimiento conversion", path: "/movimiento-conversion" },
  { icon: "", text: "Movimientos internos", path: "/movimientos-internos" },
  { icon: "", text: "Ordenes de compra", path: "/ordenes-de-compra" },
  { icon: "", text: "Pedidos de presupuesto", path: "/pedidos-de-presupuesto" },
  { icon: "", text: "Proveedores", path: "/proveedores" },
  { icon: "", text: "Remitos", path: "/remitos" },
  { icon: "", text: "Rubros", path: "/rubros" },
  { icon: "", text: "Stock Inicial", path: "/stock-inicial" },
  { icon: "", text: "Tratamientos", path: "/tratamientos" },
  { icon: "", text: "Tipo de movimientos", path: "/tipo-de-movimientos" },
  { icon: "", text: "Unidades de medida", path: "/unidades-de-medida" },
  { icon: "", text: "Usuarios", path: "/usuarios" },
  { icon: "", text: "Ventas", path: "/ventas" },
  { icon: "", text: "Simulador de Cobro", path: "/calculadora-de-cobros" },
  { icon: "", text: "Cobranzas", path: "/cobranzas"},
  { icon: "", text: "Guardar Factura", path: "/cobranzas/guardar-factura"},
  { icon: "", text: "Complejos", path: "/complejos" },
  { icon: "", text: "Unidades Funcionales", path: "/unidades-funcionales" },
  { icon: "", text: "Entrega QR", path: "/entrega_qr" }
];
