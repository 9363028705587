const $BASE_API = "https://divannibackendyii.jeds.ar";
const $BASE_REST = "https://divannibackendyii.jeds.ar/web";
// const $BASE_REST = "http://localhost/jeds/divanni_backend_yii/web";

export const environment = {
      production: false,
      baseRest: $BASE_REST,
      login: {
            users: `${$BASE_REST}/view_usuario_logins`,
            login: `${$BASE_REST}/usuario/login`,
            view_usuarios: `${$BASE_REST}/view_usuarios`,
            credencial_usuario: `${$BASE_REST}/view_usuario/generar_credencial`
      },
      administracion: {
            tratamientos: `${$BASE_REST}/tratamientos`,
            bancos: `${$BASE_REST}/fondos_bancos`,
            proveedores: `${$BASE_REST}/proveedors`,
            view_proveedores: `${$BASE_REST}/view_proveedors`,
            view_ven_pago_tipos: `${$BASE_REST}/view_ven_pago_tipos`,
            ven_pago_tipos: `${$BASE_REST}/ven_pago_tipos`,
            clientes: `${$BASE_REST}/adm_clientes`,
            view_clientes: `${$BASE_REST}/view_clientes`,
            egr_tipo_pagos: `${$BASE_REST}/egr_tipo_pagos`,
            condicion_iva: `${$BASE_REST}/select?modelo=condicioniva&campo_id=codigo&campo_descripcion=descripcion&campo_activo=activo`
      },
      fondos: {
            fondos: `${$BASE_REST}/fondos_fondos`,
            view_fondos: `${$BASE_REST}/view_fondos`,
            fondos_tipo: `${$BASE_REST}/fondos_tipos`,
            movimientos: `${$BASE_REST}/fondos_movimientos`,
            view_movimientos: `${$BASE_REST}/view_movimientos`
      },
      stock: {
            articulos: `${$BASE_REST}/stk_articulos`,
            articulo_unidad_medidas: `${$BASE_REST}/stk_articulo_unidad_medidas`,
            depositos: `${$BASE_REST}/stk_depositos`,
            entregas: `${$BASE_REST}/stk_entregas`,
            unidad_medidas: `${$BASE_REST}/stk_unidad_medidas`,
            movimiento_conversion: `${$BASE_REST}/stk_movimiento_conversions`,
            movimiento_internos: `${$BASE_REST}/stk_movimiento_internos`,
            remitos: `${$BASE_REST}/stk_remitos`,
            rubros: `${$BASE_REST}/stk_rubros`,
            reporte_etiqueta: `${$BASE_REST}/stk_articulo/generar_etiquetas`,
            reporte_entrega: `${$BASE_REST}/stk_entrega/generar_reporte?identrega=`,
            reporte_movimiento_internos: `${$BASE_REST}/stk_movimiento_interno/generar_reporte?idmovimientointerno=`,
            stock_inicial: `${$BASE_REST}/stk_stock_inicials`,
            ver_imagen: `${$BASE_REST}`, // uploads/nombre_imagen se lo concatena en otros componentes
            view_articulos: `${$BASE_REST}/view_articulos`,
            view_stock_inicials: `${$BASE_REST}/view_stock_inicials`,
            view_stock_movimientos: `${$BASE_REST}/view_stock_movimientos`,
            ver_reporte_movimiento_internos: `${$BASE_REST}/`,
            view_stock_articulo_depositos: `${$BASE_REST}/view_stock_articulo_depositos`,
            view_items_oc_pendientes: `${$BASE_REST}/view_items_oc_pendientes`,
            view_stock_entregas: `${$BASE_REST}/view_stock_entregas`,
            view_stock_depositos: `${$BASE_REST}/view_stock_depositos`,
            entregar: `${$BASE_REST}/stk_entrega/entregar?identrega=`,
            stk_entrega_puede_crear: `${$BASE_REST}/stk_entrega/puede_crear`,
            stk_articulo_adjuntos: `${$BASE_REST}/stk_articulo/adjuntos`,
            stk_articulo_obtener_adjuntos: `${$BASE_REST}/stk_articulo/obtener_adjuntos`,
            stk_articulo_ordenar: `${$BASE_REST}/stk_articulo/ordenar`
      },
      ventas: {
            view_venta_selects: `${$BASE_REST}/view_venta_selects`,
            view_venta_genericas: `${$BASE_REST}/view_venta_genericas`
      },
      compras: {
            compras_cotizacion: `${$BASE_REST}/compras_cotizacion`,
            compras_pedidos: `${$BASE_REST}/compras_pedidos`,
            view_compras_cotizacion: `${$BASE_REST}/view_compras_cotizacion`,
            view_compras_cotizacion_item: `${$BASE_REST}/view_compras_cotizacion_item`,
            view_compras_pedidos: `${$BASE_REST}/view_compras_pedidos`,
            view_compras_pedido_item: `${$BASE_REST}/view_compras_pedido_item`,
            orden_compra: `${$BASE_REST}/compras_orden_compras`,
            orden_compra_items: `${$BASE_REST}/compras_orden_compra_items`,
      },
      localidades: `${$BASE_REST}/select?modelo=localidad&campo_id=codigo&campo_descripcion=descripcion&campo_activo=`,
      controlador: `${$BASE_API}/controlador`,
      menu: `${$BASE_REST}/com_menus`,
      empresa: {
            api: `${$BASE_REST}/com_menus`,
            uploads: `${$BASE_REST}`,
      },
      notificaciones: {
            view_notificaciones: `${$BASE_REST}/view_notificacions`,
            view_notificaciones_group: `${$BASE_REST}/view_notificacion_group`
      },
      inmuebles: {
            complejos: {
                  inm_complejos: `${$BASE_REST}/inm_complejos`,
                  view_inm_complejos: `${$BASE_REST}/view_inm_complejos`
            },
            unidad_funcional: {
                  inm_unidad_funcional: `${$BASE_REST}/inm_unidad_funcional`,
                  view_inm_unidad_funcional: `${$BASE_REST}/view_inm_unidad_funcionals`
            }
      },
      reporte_orden_servicio: `${$BASE_REST}/generar_orden_servicios`
};
