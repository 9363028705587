<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
    <button mat-icon-button *ngIf="!opened" (click)="open()">
        <mat-icon [svgIcon]="'search'" class="search-blink"></mat-icon>
        <span *ngIf="_searchService.getSearchInput().value" class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-primary-500 text-indigo-50 text-xs font-medium">
                {{ _searchService.getSearchInput().value.trim().substring(0,5)+"..." }}
            </span>
        </span>
    </button>
    <button id="filtroGeneral" mat-icon-button type="button" (click)="eventoFiltro($event,filterGral)" #filterGral>
        <svg id="adjustments-vertical" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
        </svg>
    </button>
    <div class="absolute inset-0 flex items-center shrink-0 z-99 bg-card" *ngIf="opened" @slideInTop @slideOutTop>
        <mat-icon class="absolute ml-6 sm:ml-8" [svgIcon]="'search'"></mat-icon>
        <input #barSearchInput class="w-full h-full px-16 sm:px-18" [formControl]="_searchService.getSearchInput()"
            autocomplete="off" [placeholder]="'Search...'"
            (ngModelChange)="this._searchService.getSearchText().next($event)" (keydown)="onKeydown($event)" />
        <button class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5" mat-icon-button (click)="close()">
            <svg id="x-mark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
    </div>
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'">
    <div class="w-full sm:min-w-80">
        <mat-form-field class="fuse-mat-no-subscript w-full">
            <mat-icon matPrefix [svgIcon]="'search'"></mat-icon>
            <input #barSearchInput class="w-full h-full px-16 sm:px-18" [formControl]="_searchService.getSearchInput()"
                autocomplete="off" [placeholder]="'Search...'"
                (ngModelChange)="this._searchService.setSearchText($event)" (keydown)="onKeydown($event)" />
        </mat-form-field>
    </div>
</ng-container>