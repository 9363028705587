import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { SafeUrlPipe } from "@shared/pipes/safe-url.pipe";
import { JedsMenuService } from "@shared/service/app/jeds-menu.service";
import { PwaService } from "@shared/service/app/pwa.service";
import { LectorQrComponent } from "./lector-qr/lector-qr.component";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { EnviarEmailModalComponent } from "./enviar-email-modal/enviar-email.component";
import { MatChipsModule } from "@angular/material/chips";
import { EnviarEmailModalService } from "./enviar-email-modal/enviar-email.service";
import { QuillModule } from "ngx-quill";
import { AppAlertService } from "@shared/service/app/alert.service";

@NgModule({
    declarations: [
        SafeUrlPipe,
        LectorQrComponent, // Asegúrate de que este componente esté declarado
        EnviarEmailModalComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatInputModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatDividerModule,
        MatAutocompleteModule,
        OverlayModule,
        PortalModule,
        ZXingScannerModule, // Importa ZXingScannerModule aquí
        MatChipsModule,
        FormsModule,
        QuillModule.forRoot() // Configuración global del módulo
    ],
    exports: [
        SafeUrlPipe,
        LectorQrComponent, // Exporta LectorQrComponent para que esté disponible en otros módulos
        EnviarEmailModalComponent
    ],
    providers: [
        JedsMenuService,
        PwaService,
        EnviarEmailModalService,
        AppAlertService
    ],
})
export class SharedComponentModule { }
