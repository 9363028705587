<div class="container relative">
    <!-- Botón para detener el escáner y cerrar el diálogo -->
    <button class="close-btn" mat-icon-button color="accent" *ngIf="scannerEnabled" (click)="stopScanner()">
        <mat-icon>close</mat-icon>
    </button>

    <div class="scanner-container">
        <zxing-scanner id="scanner" *ngIf="scannerEnabled" [start]="scannerEnabled" [device]="selectedDevice"
            (scanSuccess)="onCodeResult($event)" (camerasFound)="onCamerasFound($event)"
            (permissionResponse)="onHasPermission($event)" [formats]="['QR_CODE']" [tryHarder]="true">
        </zxing-scanner>
    </div>  

    <div class="control-buttons">
        <!-- Botón para cambiar cámara -->
        <button mat-icon-button color="primary" *ngIf="scannerEnabled" (click)="switchCamera()">
            <mat-icon>switch_camera</mat-icon>
        </button>

        <!-- Botón para encender/apagar el flash -->
        <button mat-icon-button color="warn" *ngIf="scannerEnabled" (click)="toggleTorch()">
            <mat-icon>flash_on</mat-icon>
        </button>
    </div>
</div>