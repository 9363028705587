import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

// Extender el tipo MediaTrackConstraintSet para incluir torch
interface MediaTrackConstraintSet {
  torch?: boolean; // Propiedad torch para controlar la linterna
}

@Component({
  selector: 'app-lector-qr',
  templateUrl: './lector-qr.component.html',
  styleUrls: ['./lector-qr.component.scss']
})
export class LectorQrComponent implements OnInit, OnDestroy {
  @Output() sendObject = new EventEmitter<any>();
  scannerEnabled: boolean = true; // Control para activar o detener el escáner
  availableDevices: MediaDeviceInfo[] = [];
  selectedDevice: MediaDeviceInfo | null = null;
  hasDevices: boolean = false;
  hasPermission: boolean = false;
  currentDeviceIndex: number = 0; // Índice de la cámara actual
  torchEnabled: boolean = false; // Control para la linterna

  constructor(private dialogRef: MatDialogRef<LectorQrComponent>) { }

  ngOnDestroy(): void {
    this.stopScanner();
  }

  ngOnInit(): void {
    // Inicializar el escáner si es necesario
  }

  onCodeResult(resultString: string) {
    this.sendObject.emit(resultString);
    this.stopScanner();
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
    if (this.hasDevices) {
      // Seleccionar la primera cámara disponible
      this.selectedDevice = devices[0];
      this.currentDeviceIndex = 0; // Inicializar índice de la cámara
    }
  }

  onHasPermission(has: boolean): void {
    this.hasPermission = has;
  }

  // Método para detener el escáner
  stopScanner(): void {
    if (this.scannerEnabled) { // Verifica si el escáner está habilitado
      this.scannerEnabled = false; // Desactivar el escáner

      console.log("Escáner detenido."); // Mensaje de confirmación

      // Manejo del backdrop overlay si se está ejecutando dentro de un modal
      const overlayBackdrop = document.querySelector('.cdk-overlay-backdrop');
      if (overlayBackdrop) {
        // Verifica si hay más de un overlay
        const overlays = document.querySelectorAll('.cdk-overlay-backdrop');
        if (overlays.length > 1) {
          // Elimina el último overlay
          overlays[overlays.length - 1].remove();
        } else {
          // Si solo hay un overlay, elimina ese
          overlayBackdrop.remove();
        }
      }
    }
    
    // Cerramos el diálogo
    this.dialogRef.close();
  }

  startScanner(): void {
    this.scannerEnabled = true;
  }

  // Método para cambiar entre cámaras
  switchCamera(): void {
    if (this.hasDevices) {
      this.currentDeviceIndex = (this.currentDeviceIndex + 1) % this.availableDevices.length; // Cambiar al siguiente índice
      this.selectedDevice = this.availableDevices[this.currentDeviceIndex]; // Actualizar la cámara seleccionada
      console.log(`Cámara cambiada a: ${this.selectedDevice.label}`); // Mensaje de confirmación
      // Aquí puedes reiniciar el escáner si es necesario
    }
  }

  // Método para encender y apagar la linterna
  toggleTorch(): void {
    if (this.selectedDevice) {
      const constraints = {
        video: {
          deviceId: { exact: this.selectedDevice.deviceId },
          facingMode: 'environment' // Asegúrate de usar la cámara trasera
        }
      };

      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          const videoTrack = stream.getVideoTracks()[0];
          if (videoTrack) {
            const capabilities = videoTrack.getCapabilities();
            if (capabilities['torch']) { // Verifica si el dispositivo tiene linterna
              this.torchEnabled = !this.torchEnabled; // Cambia el estado de la linterna
              // Aplicar las restricciones avanzadas
              let advanced = 'advanced';
              videoTrack.applyConstraints({ [advanced]: [{ torch: this.torchEnabled }] as MediaTrackConstraintSet })
                .then(() => {
                  console.log(`Linterna ${this.torchEnabled ? 'encendida' : 'apagada'}.`);
                })
                .catch(err => {
                  console.error('Error al cambiar el estado de la linterna:', err);
                });
            }
          }
        })
        .catch(err => {
          console.error('Error al acceder a la cámara:', err);
        });
    }
  }
}