// @Component({
//     selector: 'app-save-stock-inicial',
//     templateUrl: './save.component.html',
//     styleUrls: ['./save.component.scss'],
//     providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }]
// })


/**
 * Formateo de fecha
 */
export const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'mm/dd/yyyy',
    },
    display: {
        dateInput: 'dd/MM/yyyy',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM yyyy',
    },
};