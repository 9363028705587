<div class="search-container mt-2 mb-1 flex">
    <div class="relative w-full md:w-1/3">
        <input matInput [(ngModel)]="filter" (input)="applyFilter($event)" placeholder="Buscar..."
            class="w-full bg-slate-50 border border-gray-200 rounded-lg shadow-md py-2 px-4 text-sm placeholder-gray-500 text-gray-700 transition duration-300" />

        <!-- Botón de limpiar -->
        <button *ngIf="filter" (click)="resetFilter()" class="absolute inset-y-0 right-10 flex items-center text-gray-400 hover:text-gray-700 p-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M6 6L18 18M6 18L18 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
            </svg>
        </button>

        <!-- Icono de busqueda -->
        <span class="absolute inset-y-0 right-4 flex items-center text-gray-400">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                    d="M12.9 14.32a8 8 0 111.42-1.42l4.39 4.4a1 1 0 11-1.42 1.41l-4.4-4.39zM8 14a6 6 0 100-12 6 6 0 000 12z"
                    clip-rule="evenodd" />
            </svg>
        </span>

    </div>
</div>