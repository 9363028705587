import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PwaService {
    isPwa(): boolean {
        return window.matchMedia('(display-mode: standalone)').matches ||
            window.matchMedia('(display-mode: fullscreen)').matches;
    }
}