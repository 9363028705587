import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss']
})
export class GridFilterComponent {
  filter: string = '';

  @Output() filterChange: EventEmitter<string> = new EventEmitter<string>();


  /**
  * Filtro para buscar en la grilla
  * @param filterValue 
  */
  applyFilter(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.filter = input.value.trim().toLowerCase();
    this.filterChange.emit(this.filter);
  }

  /**
  * Borra el filtro y emite un valor vacío para resetear la tabla
  */
  resetFilter() {
    this.filter = '';
    this.filterChange.emit('');
  }

}
