import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationGroup, Notification } from 'app/layout/common/notifications/notifications.types';
import { environment } from 'environments/environment';
import { of,map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';

@Injectable({providedIn: 'root'})
export class NotificationsService
{
    private _notifications_group: ReplaySubject<NotificationGroup[]> = new ReplaySubject<NotificationGroup[]>(1);
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notificationsGroup$(): Observable<NotificationGroup[]>
    {
        return this._notifications_group.asObservable();
    }

    get notifications$(): Observable<Notification[]>
    {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Notificationes Agrupadas
     */
    getNotificationGroup(): Observable<NotificationGroup[]>
    {
        return this._httpClient.get<any>(environment.notificaciones.view_notificaciones_group).pipe(
            map((response) => {
                if (Array.isArray(response.result)) {
                    const notifications_group: NotificationGroup[] = [];
                    response.result.forEach((viewNotificationGroup) => {
                        const description = viewNotificationGroup.cantidad > 1 
                            ? `Tienes ${viewNotificationGroup.cantidad} notificaciones sin leer.` 
                            : `Tienes ${viewNotificationGroup.cantidad} notificación sin leer.`;
                        notifications_group.push({
                            id: viewNotificationGroup.idconfiguracion.toString(),
                            icon: viewNotificationGroup.icono,
                            title: viewNotificationGroup.descripcion,
                            description,
                            read: false,
                        });
                    });
                    this._notifications_group.next(notifications_group);
                    return notifications_group;
                } else {
                    console.error('Error en traer las notificaciones agrupadas.');
                    return [];
                }
            }),
        );
    }

    /**
     * Get Notificationes
     */
    getNotification(): Observable<Notification[]>
    {
        return this._httpClient.get<any>(environment.notificaciones.view_notificaciones).pipe(
            map((response) => {
                if (Array.isArray(response.result)) {
                    const notifications: Notification[] = [];
                    response.result.forEach((viewNotification) => {
                        notifications.push({
                            id: viewNotification.idconfiguracion.toString(),
                            icon: viewNotification.icono,
                            title: viewNotification.descripcion,
                            description: viewNotification.detalle,
                            date: viewNotification.fecha,
                            link: viewNotification.url,
                            read: false,
                        });
                    });
                    this._notifications.next(notifications);
                    return notifications;
                } else {
                    console.error('Error en traer las notificaciones.');
                    return [];
                }
            }),
        );
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    // create(notification: Notification): Observable<Notification>
    // {
    //     return this.notifications$.pipe(
    //         take(1),
    //         switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', {notification}).pipe(
    //             map((newNotification) =>
    //             {
    //                 // Update the notifications with the new notification
    //                 this._notifications.next([...notifications, newNotification]);

    //                 // Return the new notification from observable
    //                 return newNotification;
    //             }),
    //         )),
    //     );
    // }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: NotificationGroup): Observable<NotificationGroup>
    {
        return this.notificationsGroup$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.patch<NotificationGroup>('api/common/notifications', {
                id,
                notification,
            }).pipe(
                map((updatedNotification: NotificationGroup) =>
                {
                    // Find the index of the updated notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Update the notification
                    notifications[index] = updatedNotification;

                    // Update the notifications
                    this._notifications_group.next(notifications);

                    // Return the updated notification
                    return updatedNotification;
                }),
            )),
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean>
    {
        return this.notificationsGroup$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.delete<boolean>('api/common/notifications', {params: {id}}).pipe(
                map((isDeleted: boolean) =>
                {
                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications_group.next(notifications);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean>
    {
        return this.notificationsGroup$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
                map((isUpdated: boolean) =>
                {
                    // Go through all notifications and set them as read
                    notifications.forEach((notification, index) =>
                    {
                        notifications[index].read = true;
                    });

                    // Update the notifications
                    this._notifications_group.next(notifications);

                    // Return the updated status
                    return isUpdated;
                }),
            )),
        );
    }
}
