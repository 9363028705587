import { ConnectedPosition } from '@angular/cdk/overlay';

export const IMenuPosition: IConnectedPositions = {
    topLeft: [{ originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'top' }],
    topRight: [{ originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'top' }],
    bottomLeft: [{ originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'bottom' }],
    bottomRight: [{ originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'bottom' }],
};

export interface IConnectedPositions {
    topLeft: ConnectedPosition[];
    topRight: ConnectedPosition[];
    bottomLeft: ConnectedPosition[];
    bottomRight: ConnectedPosition[];
}